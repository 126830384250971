<template>
  <div>
    <base-header class="pb-6 pb-8 pt-6 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6" v-for="(workflow, key) in workflows" :key="key">
          <stats-card type="gradient-red"
                      :sub-title="workflow.name"
                      :icon="getTaskIcon(workflow.code)"
                      :description="workflow.description"
                      :no-footer="workflow.code === '01' || workflow.code === '02' || workflow.code === '10'"
                      class="mb-4">

            <template slot="footer">
              <b-link v-if="workflow.code === '21'" :to="{ name: 'vacations', hash: '#vacations_histories' }">
                <b-icon icon="clock-history" variant="success"></b-icon>
                <span class="text-nowrap ml-2" style="font-size: 12px;">休暇履歴</span>
              </b-link>
              <b-link v-else :to="{path: `/task/workflows/${workflow.code}/create`}">
                <b-icon icon="plus-circle" variant="success"></b-icon>
                <span class="text-nowrap ml-2" style="font-size: 12px;">新規作成</span>
              </b-link>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>
  </div>
</template>

<script>
import { ajax, config, hostFront } from '../../utils';

export default {
  data() {
    return {
      loading: true,
      workflows: [],
    }
  },
  created: function () {
    document.title = "各種手続き"
    this.getWorkflowList();
  },
  methods: {
    getWorkflowList: function() {
      const vm = this;
      ajax.fetchGet(config.api.task.workflow_list).then(res => {
        res.results.map(item => {
          if (item.code === '01' || item.code === '02') {
            // 証明書発行
            item.description = `2023年9月22日より「証明書発行」と「証明書発行（保育園用）」は、Larkに移行します。お手数ですが、Larkよりご申請下さい。尚、操作マニュアルは<a class='badge bg-primary link' href='${hostFront}/manual/2-2%20各証明書の発行.pdf'>こちら</a>となります。`
          } else if (item.code === '10') {
              item.description = `2024年6月1日より「定期券申請」は、Larkに移行します。お手数ですが、Larkよりご申請下さい。尚、操作マニュアルは<a class='badge bg-primary link' href='${hostFront}/manual/3-9%20通勤定期代申請手順.pdf'>こちら</a>となります。`
          }
        })
        vm.workflows = res.results;
      }).finally(() => {
        vm.loading = false;
      });
    },
    getTaskIcon: function(code) {
      if (code === '01') {
        // 証明書発行
        // return "fas fa-file-alt";
        return null;
      } else if (code === '02') {
        // 証明書発行(保育園用)
        // return "fas fa-baby-carriage";
        return null;
      } else if (code === '03') {
        // ビザ変更
        return "fas fa-id-card";
      } else if (code === '10') {
        // 通勤変更
        return null
      } else if (code === '11') {
        // 経費精算
        return "fas fa-euro-sign";
      } else if (code === '20') {
        // 休暇申請
        return "fas fa-plane-departure";
      } else if (code === '21') {
        // 休暇取消
        return "fas fa-plane-slash";
      } else {
        // 上記以外の場合
        return "fas fa-question";
      }
    },
  },
}
</script>